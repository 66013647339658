<template>
 <div>
  
     <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Resturant") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{name: 'ingredient'}">{{ $t("Ingredients") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id? $t("Edit"): $t('Add') }}</label>

    </div>

  <v-toolbar-title class="mt-3">{{$route.params.id? $t('EditIngredient')  : $t('AddNewIngredient') }}</v-toolbar-title>


   <v-tabs class="mt-2"
      v-model="activeName"
      background-color="primary"
      dark
    >
      <v-tab>{{ $t('PrimaryData') }}</v-tab>
      <v-tab>{{ $t('NutritionFacts') }}</v-tab>
   </v-tabs>

    <v-tabs-items v-model="activeName">

       <v-tab-item>
           <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_ar`),
              }"
            >
             <label>{{$t('NameAr')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_ar"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NameAr')"
                hide-details
                dense
                outlined
                v-model="formData.name.ar"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_ar`)"
            >
              {{ errors.first(`addEditValidation.name_ar`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.name_en`),
              }"
            >
             <label>{{$t('NameEn')}}</label>
              <v-text-field
                class="d-block my-2"
                type="text"
                name="name_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('NameEn')"
                hide-details
                dense
                outlined
                v-model="formData.name.en"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.name_en`)"
            >
              {{ errors.first(`addEditValidation.name_en`) }}
            </div>
          </v-col>


          <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.description_ar`),
              }"
            >
              <label>{{$t('DescriptionAr')}}</label>
              <v-textarea
                class="d-block my-2"
                type="text"
                name="description_ar"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('DescriptionAr')"
                hide-details
                dense
                outlined
                rows="3"
                v-model="formData.description.ar"
              ></v-textarea>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.description_ar`)"
            >
              {{ errors.first(`addEditValidation.description_ar`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="6">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.description_en`),
              }"
            >
            <label>{{$t('DescriptionEn')}}</label>
              <v-textarea
                class="d-block my-2"
                type="text"
                name="description_en"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('DescriptionEn')"
                hide-details
                dense
                outlined
                rows="3"
                v-model="formData.description.en"
              ></v-textarea>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.description_en`)"
            >
              {{ errors.first(`addEditValidation.description_en`) }}
            </div>
          </v-col>


            <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.unit`),
              }"
            >
            <label>{{$t('Unit')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="unit"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Unit')"
                hide-details
                dense
                outlined
                v-model="formData.unit_id"
                item-value="id"
                item-text="name"
                :items="listUnit"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.unit`)"
            >
              {{ errors.first(`addEditValidation.unit`) }}
            </div>
          </v-col>

             <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.division`),
              }"
            >
            <label>{{$t('Division')}}</label>
             <v-autocomplete
                class="d-block my-2"
                name="division"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Division')"
                hide-details
                dense
                outlined
                v-model="formData.division_id"
                item-value="id"
                item-text="name"
                :items="listDivision"
                clearable
              ></v-autocomplete>
              
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.division`)"
            >
              {{ errors.first(`addEditValidation.division`) }}
            </div>
          </v-col>

           <v-col class="py-0" :cols="4">
            <div
              :class="{
                'has-error': errors.has(`addEditValidation.cost`),
              }"
            >
            <label>{{$t('Cost')}}</label>
              <v-text-field
                class="d-block my-2"
                type="number"
                name="cost"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Cost')"
                hide-details
                dense
                outlined
                v-model="formData.cost"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cost`)"
            >
              {{ errors.first(`addEditValidation.cost`) }}
            </div>
          </v-col>

              <v-col :cols="2">
                <span>{{$t('Primary')}}</span>
              </v-col>
            <v-col class="py-0" :cols="4">
               <v-switch
                class="d-block my-2"
                type="number"
                name="cost"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Cost')"
                hide-details
                dense
                outlined
                v-model="formData.main" 
              ></v-switch>
            </v-col>

        </v-row>
      </v-container>
    </v-card-text>

       </v-tab-item>


        <v-tab-item>
         <v-container fluid>
           <div>
            <el-button @click="addNew" type="primary float-right mb-5">{{$t('Add')}}</el-button>
           </div>

           <table id="pacakgeFeaturesTable">
            <tr>
              <th>#</th>
              <th>الحقيقة الغدائية</th>
              <th>الوحدة</th>
              <th>القيمة</th>
              <th>العمليات</th>
            </tr>

            <tr v-for="(l, lIndex) in formData.nutriotion_fact" :key="lIndex">
              <td>
              {{  lIndex + 1}}
              </td>
              <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.nutriotion_fact_id${lIndex}`),
              }"
            >
            <label>{{$t('NutriotionFact')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`nutriotion_fact_id${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('NutriotionFact')"
                hide-details
                dense
                outlined
                v-model="l.nutriotion_fact_id"
                item-value="id"
                item-text="name"
                :items="listNutrtionFact"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.nutriotion_fact_id${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.nutriotion_fact_id${lIndex}`) }}
            </div>
              </td>

              <td>

                <div
              :class="{
                'has-error': errors.has(`addEditValidation.unit_ids${lIndex}`),
              }"
            >
            <label>{{$t('Unit')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`unit_ids${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Unit')"
                hide-details
                dense
                outlined
                v-model="l.unit_ids"
                item-value="id"
                item-text="name"
                :items="listUnit"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.unit_ids${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.unit_ids${lIndex}`) }}
            </div>
            
              </td>
              <td>
                 <div
              :class="{
                'has-error': errors.has(`addEditValidation.value${lIndex}`),
              }"
            >
            <label>{{$t('Value')}}</label>
              <v-text-field
                class="d-block my-2"
                :name="`value${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Value')"
                hide-details
                dense
                outlined
                v-model="l.value"
              ></v-text-field>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.value${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.value${lIndex}`) }}
            </div>
              </td>
              <td>

                  <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            removeRow(lIndex);
          "
          color="danger"
          >mdi-delete</v-icon
        >

              </td>
            </tr>

           </table>
         </v-container>
       </v-tab-item>



    </v-tabs-items>

     <v-container fluid>
        <v-row class="float-left">
          <v-btn
            class="mr-2 ml-5"
            color="success"
            dark
            outlined
            small
            @click="save"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-row>
      </v-container>
 </div>
</template>

<script>
export default {
  name: "ingredientAddEdit",
  data() {
    return {
      activeName: 'primaryData',
      formData: {
        id: 0,
        name: {
          ar: null,
          en: null
        },
        description: {
          ar: null,
          en: null
        },
        unit_id: null,
        division_id: null,
        cost: null,
        main: false,
        nutriotion_fact: [], //{nutriotion_fact_id:3,unit_ids:10, value: 10}
      },
      listUnit: [],
      listDivision: [],
      listNutrtionFact: []
    }
  },
   methods: {
    removeRow(index) {
      this.formData.nutriotion_fact.splice(index,1)
    },
    addNew() {
      let listObj= {
        nutriotion_fact_id: null,
        unit_ids: null,
        value: null
      };
      this.formData.nutriotion_fact.push(listObj);
    },
      save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        if(this.$route.params.id) {
          //edit
          this.$store.dispatch(`ingredient/updateData`, this.formData).then((res)=> {
            this.notifySuccess("Update", res)
            this.$router.push({name:'ingredient'})
          });
        }
        else {
          this.$store.dispatch(`ingredient/saveData`, this.formData).then((res)=> {
            this.notifySuccess("Save", res)
            this.$router.push({name:'ingredient'})
          });
        }
      });
      }
    },
    created() {
      if(this.$route.params.id) {
        this.$store.dispatch(`ingredient/findData`, this.$route.params.id).then((res)=> {
          this.formData= res.data;
          });
      }

      this.$store.dispatch(`ingredient/listUnit`).then(res => {
        this.listUnit= res.data;
      })

      this.$store.dispatch(`ingredient/listDivision`).then(res => {
        this.listDivision= res.data;
      })

      this.$store.dispatch(`ingredient/listNutrtionFact`).then(res => {
        this.listNutrtionFact= res.data;
      })
    }
};
</script>

<style>

#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td, #pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04AA6D;
  color: white;
}
</style>